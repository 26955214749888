import { Card, Typography, useMediaQuery, useTheme, IconButton  } from "@mui/material";
import { useContext, useState } from "react";
import QRCode from "react-qr-code";
import { ShareSocial } from "react-share-social";
import { AuthContext } from "src/contexts/FirebaseAuthContext";
import useAgentStore from "src/hooks/UseStore";
import CloseIcon from '@mui/icons-material/Close';

export const QRComponent = () => {
  const [open, setOpen] = useState(true);
    const theme = useTheme();
    const style = {
        root: {
        background: `${theme.colors.gradients.black1}`,
        width: "100%",
        height: "200px",
        padding: '10px',
        }
    };
    const agentType = useAgentStore((state) => state.agentType);
    const {user} = useContext(AuthContext)
    let id_afiliado;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    if(agentType === 'Agente'){
        id_afiliado = user?.id_agente;
      } else if(agentType === 'SubConcesionario'){
        id_afiliado = user?.id_subconcesionario;
      } else if(agentType === 'Concesionario'){
        id_afiliado = user?.id_concesionario;
      }
      
      if (!open) return null; 
  return (
    <div>
      <Card
        sx={{
          background: `${theme.colors.gradients.black1}`,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          maxWidth: "90%",
        }}
      >
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h4"
          sx={{
            mb: 2,
            mr: 2,
            ml: 2,
            mt: 2,
            color: "white",
            textAlign: "center",
          }}
        >
          Escanea el código QR para afiliarte
        </Typography>
          {
            isMobile ? <QRCode value={`https://www.sivarbet.com/auth/register/?id_afiliado=${id_afiliado}`} /> 
            : 
            <QRCode value={`https://www.sivarbet.com/?id_afiliado=${id_afiliado}`} /> 
          }

        <ShareSocial
          style={style}
          url={ 
            isMobile ?
            `https://www.sivarbet.com/auth/register/?id_afiliado=${id_afiliado}` : 
            `https://www.sivarbet.com/?id_afiliado=${id_afiliado}`
          } 
          socialTypes={["whatsapp","facebook", "twitter", "telegram", "email"]}
          onSocialButtonClicked={ () => console.log('shared')}  
        />
      </Card>
    </div>
  );
};
