import {
  Box, Stack, Divider, Typography, Button, useTheme, useMediaQuery,
  TextField, Dialog, DialogTitle, DialogContent, DialogActions, Select,styled,alpha, MenuItem, FormControl, InputLabel
} from '@mui/material';
import HeaderUserbox from "./Userbox";
import IconButton from "@mui/material/IconButton";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { useContext, useState } from "react";
import { SidebarContext } from "src/contexts/SidebarContext";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { AuthContext } from 'src/contexts/FirebaseAuthContext';
import { QRComponent } from './QR/QRComponent';
import useAgentStore from 'src/hooks/UseStore';



const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 9999;
        background: linear-gradient(100.66deg, #434343 18.56%, #000000 80.57%);
        backdrop-filter: blur(6px);
        position: fixed;
        justify-content: space-between;
`
);

function Header() {
  const theme = useTheme();
  const [qrVisible, setQrVisible] = useState(false);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { user } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [subConcesionario, setSubConcesionario] = useState('');
  const [subConcesionarios, setSubConcesionarios] = useState([]);
  const [agentes, setAgentes] = useState('');
  const [agentesList, setAgentesList] = useState([]);
  const [amount, setAmount] = useState(''); 
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [pin, setPin] = useState('');
  const agentType = useAgentStore((state) => state.agentType);



  const handleClose = () => {
    setModalOpen(false);
    setSubConcesionarios([]);
    //setAgentesList(['']);
    setAmount('');

  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleQrVisibility = () => {
    setQrVisible(!qrVisible);
  };


  const handleConfirmRecargaClick = () => {
    if (amount <= 0) {
      alert("Porfavor entra un monto válido para recargar.");
      return;
    }
    setConfirmOpen(true);
    setModalOpen(false);
  };

  const handleSubConcesionarioChange = (event) => {
    setSubConcesionario(event.target.value);
    // Mocking the update of agentes list based on selected subconcesionario
    // setAgentesList([{ id: 'ag1', name: 'Agente 1' }, { id: 'ag2', name: 'Agente 2' }]);
  };

  const handleAgentesChange = (event) => {
    setAgentes(event.target.value);
  };

  const handleConfirmationClose = () => {
    setConfirmOpen(false);
    setModalOpen(true);
  };

  const handleRecargaConfirm = () => {
    console.log("Recarga confirmed for ID:", agentes || subConcesionario, "Amount:", amount, "PIN:", pin);
    // Implement the API call to process the recharge here
    setConfirmOpen(false);
  };

  return (
    <>
      <HeaderWrapper display="flex" alignItems="center" left={0}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="center"
          spacing={2}
        >
          {!isMobile && (
            <IconButton onClick={toggleSidebar}>
              {sidebarToggle ? (
                <CloseTwoToneIcon fontSize="medium" />
              ) : (
                <MenuTwoToneIcon fontSize="medium" />
              )}
            </IconButton>
          )}
          {agentType === 'Agente'? (
              <IconButton  onClick={toggleQrVisibility} sx={{ color: 'black' }}>
              <QrCodeScannerIcon fontSize="large" />
            </IconButton>
          ): null}
   
        </Stack>
        <Box display="flex" alignItems="center">
          <Box
            component="span"
            sx={{
              ml: 2,
              display: { xs: "inline-block" },
            }}
          ></Box>
            <Button
              variant="contained"
              disabled
              sx={{
                p: 1,
                backgroundColor: 'lightgreen',
                color: 'black', // Ensures the button text is black
                '&.Mui-disabled': {
                  backgroundColor: 'lightgreen', // Keeps the background color when disabled
                  color: 'black', // Keeps the text color black when disabled
                  opacity: 1, // Removes the default opacity effect for disabled buttons
                },
              }}
            >
              <Typography variant="h5" sx={{ color: 'black' }}>
                $ {user?.saldo}
              </Typography>
            </Button>
          <HeaderUserbox />
        </Box>
      </HeaderWrapper>
      {qrVisible && (
        <QRComponent/>
      )}
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} sx={{ '& .MuiDialog-paper': { backgroundColor: 'background.paper', color: 'text.primary' } }}>
        <DialogTitle>Recargar Saldo</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="subconcesionario-label">Subconcesionario</InputLabel>
            <Select
              labelId="subconcesionario-label"
              value={subConcesionario}
              onChange={handleSubConcesionarioChange}
              displayEmpty
            >
              {subConcesionarios.map((sub) => (
                <MenuItem key={sub.id} value={sub.id}>{sub.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="agentes-label">Agentes</InputLabel>
            <Select
              labelId="agentes-label"
              value={agentes}
              onChange={handleAgentesChange}
              displayEmpty
            >
              {agentesList.map((agente) => (
                <MenuItem key={agente.id} value={agente.id}>{agente.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            required
            label="Monto a Depositar"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleConfirmRecargaClick}>Confirmar Recarga</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleConfirmationClose} sx={{ '& .MuiDialog-paper': { backgroundColor: 'background.paper', color: 'text.primary' } }}>
        <DialogTitle>Confirmar Recarga</DialogTitle>
        <DialogContent>
          <Typography>Vas a recargar ${amount}. Por favor, ingresa tu PIN para confirmar</Typography>
          <TextField
            type="password"
            inputProps={{ maxLength: 4 }}
            fullWidth
            autoFocus
            margin="normal"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose}>Cancelar</Button>
          <Button onClick={handleRecargaConfirm} color="primary" variant="contained">Recargar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Header;
